import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/worker/code/src/templates/blog-post.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`For those of you who weren’t at the `}<a parentName="p" {...{
        "href": "http://money2020.com/"
      }}>{`Money2020`}</a>{` conference this past week in Las Vegas, I wanted to share this imaginative and illustrative infographic that was displayed front and center throughout the event. I tried to snap a photo of it on many occasions but it was getting so much attention from the 2,000 attendees that each of my attempts had several people blocking my shot.`}</p>
    <p>{`Now, why did I go through the trouble of tracking down this infographic? Well, there’s a few things about it I want to highlight:`}</p>
    <p>{`The illustrations are genius: Who doesn’t love a good caricature? No one. So why did it take this long for someone to figure out that illustrating your conference speakers would get so many people so excited? I don’t know. But I’m glad it happened before 2020.`}</p>
    <p>{`A picture is worth 1,000 words: It’s amazing how much information is communicated in this picture. To communicate how the finance industry is organized, key companies/people in each orbit, and how each orbit relates to each other would take several thousand words…that likely no one would read. But here, you had people lining up to learn the story.`}</p>
    <p>{`They got the story right: Two things that stood out to me as true, accurately captured and sometimes overlooked were: 1) the importance of government in the payments ecosystem (notice Melissa Koide, from Treasury, right smack dab in the middle) and 2) the recognition that financial innovations for the underserved are part and parcel to the traditional payments landscape.`}</p>
    <p>{`Kudos to the Money2020 team for getting this, among a ton of other things right at the first (annual!) conference.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1356px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "33%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAHCAIAAACHqfpvAAAACXBIWXMAAAsTAAALEwEAmpwYAAABRklEQVQY01WPyY6jMBiEefV5qX6APs5IrSSdRRCbxcF2vIGbEAPe/hHH/g51KOlTqQpjp5YKKg2ThgtZlqhBd600uuPrtURV9WT8drk0CP9MZtlmPdCfSfvgl9UVVdcjws1o2wfret5RYbS+Xm6Hw7VriBSyp5x0DyGEW17zMtlJj1YwgYVqi5qKpmfKTgAQYtoSrCn7BKwrT38/1DgAQIwRIMccEyQAeLmRckQFL45Vix+ccDnY1+LjGuIWQgL4On3++/hD2nME8GHzMfgYnQ8+hNm5O2Fl0xd6tIO1nD8Z4/tITjnFlPO8LKut/ercFvLeQ8gQU9rfMXlGpOeygN+8344QKo3l7FljUlb15XxTSnE1EK4woUKPQg/c2Lp/7nLe2RMAjDYIdz1T+I6/j9/n46ltWi0lQjVTQ02o0oaroaVifrv/3VeDXa/ZK1wAAAAASUVORK5CYII=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
            "srcSet": ["https://cdn.payperks.com/payperks-corporate/1.16.1/static/5ae6102ceb99d97d01a05783e54212f9/7f61c/infographic-money2020.webp 400w", "https://cdn.payperks.com/payperks-corporate/1.16.1/static/5ae6102ceb99d97d01a05783e54212f9/d00b9/infographic-money2020.webp 800w", "https://cdn.payperks.com/payperks-corporate/1.16.1/static/5ae6102ceb99d97d01a05783e54212f9/08aa0/infographic-money2020.webp 1356w"],
            "sizes": "(max-width: 1356px) 100vw, 1356px",
            "type": "image/webp"
          }}></source>{`
          `}<source parentName="picture" {...{
            "srcSet": ["https://cdn.payperks.com/payperks-corporate/1.16.1/static/5ae6102ceb99d97d01a05783e54212f9/e17e5/infographic-money2020.png 400w", "https://cdn.payperks.com/payperks-corporate/1.16.1/static/5ae6102ceb99d97d01a05783e54212f9/5a190/infographic-money2020.png 800w", "https://cdn.payperks.com/payperks-corporate/1.16.1/static/5ae6102ceb99d97d01a05783e54212f9/229ad/infographic-money2020.png 1356w"],
            "sizes": "(max-width: 1356px) 100vw, 1356px",
            "type": "image/png"
          }}></source>{`
          `}<img parentName="picture" {...{
            "className": "gatsby-resp-image-image",
            "src": "https://cdn.payperks.com/payperks-corporate/1.16.1/static/5ae6102ceb99d97d01a05783e54212f9/229ad/infographic-money2020.png",
            "alt": "SmallerMoney2020",
            "title": "SmallerMoney2020",
            "loading": "lazy",
            "decoding": "async",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            }
          }}></img>{`
        `}</picture>{`
    `}</span></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      